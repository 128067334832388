/* global app, _ */

'use strict';

app.controller('GsmaReminderCtrl', [
    '$rootScope',
    '$scope',
    '$q',
    '$state',
    'File',
    'User',
    'Project',
    'ngDialog',
    function ($rootScope, $scope, $q, $state, File, User, Project, ngDialog) {

        $scope.tab = 'validation';
        $scope.filters = {
            project : null,
            text: ''
        };

        $scope.pending  = null;
        $scope.refused  = null;
        $scope.projects = [];

        var addFile = function(f){
            if (f.submit_to_gsma){
                if(f.gsma_status === 'Pending'){
                    $scope.pending.push(f);
                } else if(f.gsma_status === 'Rejected') {
                    $scope.refused.push(f);
                }
            }
        };

        var load = function(){
            $scope.pending = [];
            $scope.refused = [];
            $scope.loading = true;

            User.fetchProjects($rootScope.me._id).then(function (projects) {
                return $q.all( projects.map(function(project){
                    $scope.projects.push(project);
                    return Project.toValid(project._id).then( function (files){
                        _.each( files, function(f){
                            f.project_id = project._id;
                            addFile(f);
                        });
                    });
                }));
            })
            .then(function () {
                $scope.loading = false;
            });
        };
        load();

        $scope.openComment = function (file) {
            $scope.editedFile = file;
            ngDialog.openConfirm({
                template: 'comment-edition',
                scope: $scope,
                width: '800px'
            }).then(function () {
                file.comment_organisator = $scope.editedFile.comment_organisator;
                $rootScope.loading(true);
                File.changeComment(file, file.comment_organisator).then(function () {
                    $rootScope.loading(false);
                });
            }, function () {});
        };

        $scope.setStatus = function(file, status){
            if(file.gsma_status === 'Pending'){
                _.remove($scope.pending,file);
                $scope.refused.unshift(file);
            }else{
                _.remove($scope.refused,file);
                $scope.pending.unshift(file);
            }
            File.changeStatus(file,status);
            load();
        };


        $scope.saveComment = function(file, comment){
            File.saveComment(file,comment).then( function(f){ file = f;});
        };


        $scope.projectFilter = function(file) {
            return (!$scope.filters.project || file.project_id === $scope.filters.project._id);
        };
    }
]);
