/* global app */

'use strict';

app.settings = {
    api: {
        url_dev: 'https://mwc.creativespirit-events.eu/api/',
        url: '/api/',
        // url: 'https://mwc.creativespirit-events.eu/api/',
        bearer: ''
    },
    redirect: {
        afterLogin:  'projects-selection',
        afterLogout: 'login',
        afterReject: 'login'
    }
};


if (window.location.hostname === 'cannes.creativespirit-events.eu') {
    app.settings.analytics_id = 'UA-104391497-4';
}else if (window.location.hostname === 'mwc.creativespirit-events.eu') {
    app.settings.analytics_id = 'UA-104391497-3';
} else {
    app.settings.analytics_id = 'UA-104391497-3';    
    console.log("Google analytics failover : " + app.settings.analytics_id);
}