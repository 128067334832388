/* global app: true */

'use strict';

app.controller('MainCtrl', [
    '$location',
    '$transitions',
    '$rootScope',
    '$scope',
    '$state',
    '$timeout',
    'sessionFactory',
    'websocket',
    'ngDialog',
    function ($location, $transitions, $rootScope, $scope, $state, $timeout, sessionFactory, websocket, ngDialog) {
        // Settings

        $rootScope.settings = app.settings;
        $rootScope.utils = app.utils;
        $rootScope.users_track = websocket.users;
        $rootScope.$state = $state;

        // Connexion is down warning

        $rootScope.openConnectionError = function (message) {
            ngDialog.open({
                template: '/dialogs/down',
                scope: $scope,
                showClose: false,
                closeByDocument: false
            });
        };

        // Sidebar

        $rootScope.FINANCIAL_TYPES = {
            "meeting_space": "Room rental",
            "food_and_beverage": "Food and beverage",
            "technical": "Technical equipment",
            "misc": "Miscellaneous"
        };

        $rootScope.computeFileName = function (file) {
            if (!file) {
                return;
            }

            return (file.final_client || file.company.short_name || file.company.name || '') + ' — ' + file.name + ' — #' + file.file_number;
        };

        // Loading indicator

        $scope.doLoading = false;

        $rootScope.loading = function (bool, message) {
            $scope.doLoadingMessage = message;

            if (bool) {
                $scope.doLoading = true;
            } else {
                $scope.doLoading = false;
            }
        };

        // $transitions.onSuccess({}, function(_$state) {
        //     websocket.route($location.url());
        // });
    }
]);
