/* global app */

'use strict';

app.service('Auth', [
    '$http',
    '$q',
    '$rootScope',
    'localFactory',
    'sessionFactory',
    'websocket',
    function ($http, $q, $rootScope, localFactory, sessionFactory, websocket) {
        var Auth = {
            init: function () {
                if (Auth.getToken()) {
                    Auth.setToken(Auth.getToken());
                    Auth.setInfo(Auth.getInfo());
                }
            },

            login: function (login, password) {
                return $http.post(app.settings.api.url + 'auth', {
                    login: login,
                    password: password
                }).then(function (response) {
                    if (response && (response.data.type === "organisator" || response.data.type === "extern" ) && response.data.token) {
                        Auth.setToken(response.data.token);
                        Auth.setInfo(response.data);

                        return response && response.data;
                    }

                    Auth.logout();

                    return { error: {} };

                }).catch(function (error) {
                    //console.log('Auth login error', error);

                    Auth.logout();

                    return { error: error };
                });
            },

            logout: function () {
                Auth.resetToken();
                Auth.resetInfo();
                $rootScope.me = null;
                $rootScope.selectedProject = null;
            },

            me: function () {
                return $http.get(app.settings.api.url + 'users/me').then(function (response) {
                    //console.log('Auth me ok', response);

                    if (response && response.data) {
                        Auth.setInfo(response.data);
                    }

                    return response && response.data;
                }).catch(function (error) {
                    //console.log('Auth me error', error);

                    return { error: error };
                });
            },

            setToken: function (token) {
                $http.defaults.headers.common['x-auth-token'] = app.settings.api.bearer + ' ' + token;
                localFactory.set('token', token);
            },

            getToken: function () {
                return localFactory.get('token');
            },

            resetToken: function () {
                $http.defaults.headers.common.Authorization = null;
                localFactory.destroy('token');
            },

            setInfo: function (data) {
                websocket.me(data);
                $rootScope.me = data;
                localFactory.set('me', data);
            },

            getInfo: function () {
                return localFactory.get('me');
            },

            resetInfo: function () {
                localFactory.destroy('me');
            }
        };

        return Auth;
    }
]);
