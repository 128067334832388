/* global app */

'use strict';

app.directive("formOnChange", [ '$parse', function ($parse){
    return {
        scope: false,
        link: function (scope, element, attrs) {
//            console.log('scope', scope);
            var cb = $parse(attrs.formOnChange);

            element.on("change", function(){
                cb(scope);
            });
        }
    };
}]);
