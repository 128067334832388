/* global app */

'use strict';

app.config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state('home', {
                url:         '/home',
                title:       'Home',
                templateUrl: 'views/home'
            })

            // GSMA

            .state('gsma-turnover', {
                url:         '/gsm/turnover',
                title:       'Turnover',
                templateUrl: 'views/gsma/turnover',
                private: true,
            })

            .state('gsma-reminder', {
                url:         '/gsm/reminder',
                title:       'Reminder',
                templateUrl: 'views/gsma/reminder',
                private: true,
            })

            //  CLIENT

            .state('client-files', {
                url:         '/client/files',
                title:       'File lists',
                templateUrl: 'views/client/files',
                private: true
            })

            .state('client-files-details', {
                url:         '/client/files/:id/:venue?',
                title:       'File detail',
                templateUrl: 'views/client/files/details',
                private: true
            })

            // Auth

            .state('login', {
                url:         '/login',
                title:       'Login',
                templateUrl: 'views/auth/login',
                private:     false,
                noprojectallowed: true,
            })

            .state('logout', {
                url:         '/logout',
                title:       '',
                controller:  'AuthLogoutCtrl',
                private:     false,
                noprojectallowed: true,
            });

        $urlRouterProvider.otherwise('/gsm/reminder');
    }
]);

app.run([
    '$window',
    '$state',
    '$rootScope',
    'Auth',
    function ($window, $state, $rootScope, Auth) {
//        console.log('Token', Auth.getToken());

        window.$state = $state;

        Auth.init();

        $rootScope.$on("$stateChangeStart", function (event, toState) {

            //Protected pages

            if ((toState.public === false || toState.private === true) && !Auth.getToken()) {
                $state.go(app.settings.redirect.afterReject);
                event.preventDefault();
            }

            // Change document title

            $window.document.title = toState.title;
            $window.ga('send', 'pageview');
        });
    }
]);
