/* global angular */
/* eslint no-unused-vars: 0 */

'use strict';

var app = angular.module('app', [
    'ui.router',
    'ui.router.state.events',
    'ngAnimate',
    'ngDialog',
    'ngMessages',
]);

app.config([
    '$httpProvider',
    function ($httpProvider) {
        $httpProvider.interceptors.push('ErrorInterceptor');
    }
]);

app.run([
    '$rootScope',
    '$state',
    'websocket',
    function ($rootScope, $state, websocket) {
        // Extends $state with method to check if string is contained

        // $rootScope.FINANCIAL_TYPES = {
        //     "meeting_space": "Room rental",
        //     "food_and_beverage": "Food and Beverage",
        //     "technical": "Technical",
        //     "misc": "Miscellaneous"
        // };

        websocket.init();

        // $state.matches = function (stateName) {
        //     return ($state.current.name || '').indexOf(stateName) > -1;
        // };
        // $rootScope.$state = $state;
    }
]);
