/* global app , _*/

'use strict';

app.controller('GsmaTurnoverCtrl', [
    '$rootScope',
    '$scope',
    '$q',
    '$filter',
    '$state',
    '$location',
    'ngDialog',
    'File',
    'User',
    'Project',
    'Xls',
    function ($rootScope, $scope, $q, $filter, $state, $location, ngDialog, File, User, Project, Xls) {
        $scope.filters = {
            project: null,
            text: '',
            status: 'all'
        };

        $scope.confirmed = null;
        $scope.projects = [];
        $scope.entries = null;

        $scope.groups = [
            { title: 'TO BE ACCEPTED', status: "test", submit_to_gsma: true, gsma_status: 'Pending', color: '#C3E7FB', t_color: '#0070C0' }, // ONHOLD
            // { title: 'ONHOLD', submit_to_gsma: true, gsma_status: 'Rejected', color: '#ffd5d8', t_color: '#e42b38' } // REJECTED
            { title: 'PENDING', status: 'pending', gsma_contract: { value: false }, color: '#ffede6', t_color: '#fb7c36' },
            { title: 'PENDING GSMA', status: 'pending', gsma_contract: { value: true }, color: '#ffede6', t_color: '#fb7c36' },
            { title: 'CONFIRMED', status: 'confirmed', gsma_contract: { value: false }, color: '#defff2', t_color: '#618518' },
            { title: 'CONFIRMED GSMA', status: 'confirmed', gsma_contract: { value: true }, color: '#defff2', t_color: '#618518' },
            // { title: 'TO BE ACCEPTED', submit_to_gsma: true, gsma_status: 'Pending', color: '#ffd5d8', t_color: '#e42b38' }, // ONHOLD
            { title: 'ONHOLD', status: "test2", submit_to_gsma: true, gsma_status: 'Rejected', color: '#FAFEE4', t_color: '#DCFA08' }, // REJECTED
            { title: 'CANCELLED', status: 'cancelled', color: '#ffd5d8', t_color: '#e42b38' }
        ];


        $scope.has = {
            meeting_space: false,
            food_and_beverage: false,
            technical: false,
            misc: false,
        };

        var load = function () {
            $scope.confirmed = [];
            $scope.loading = true;

            User.fetchProjects($rootScope.me._id).then(function (projects) {
                if (projects.length > 0) {
                    return $q.all(projects.map(function (project) {
                        $scope.projects.push(project);

                        _.each($rootScope.FINANCIAL_TYPES, function (name, service) {
                            $scope.has[service] |= project.visible_to_orga[service];
                        });

                        return Project.turnover(project._id).then(function (p) {
                            _.each(p.files, function (f) {
                                // if((!f.submit_to_gsma || (f.gsma_status === "Accepted")) && (f.status !== 'closed')) {
                                if (f.status !== 'closed') {
                                    f.project_id = project._id;
                                    f.comm = p.organisator_retro_commission;
                                    compute(f);
                                }
                            });
                        });
                    }));
                }
            })
                .then(function () {
                    $scope.loading = false;
                    if ($location.search().download) {
                        $scope.exportToXls();
                    }
                });
        };




        $scope.getEntries = function (group) { // Helpeur pour avoir chaqu'un des groupes 
            var res = _.filter($scope.entries, function (e) {
                return (e.group == group.status)
                    && (!group.gsma_contract || (group.gsma_contract.value === e.gsma_contract))
            });
            return res;
        };

        $scope.getValue = function (entry, field) {
            var _status = $scope.filters.status;
            if (_status === 'cancelled') {
                if (entry.status === 'cancelled')
                    return entry["pending"][field] + entry["confirmed"][field];
                else
                    return 0;
            }

            return entry[_status][field];
        };

        $scope.getSum = function (entry) {
            var total = 0;
            _.each($rootScope.FINANCIAL_TYPES, function (name, service) {
                total += $scope.getValue(entry, service);
            });
            return total;
        };


        $scope.isNew = function (date) {
            const daysDelta = 1;
            const infoDate = new Date(date);
            var yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - daysDelta);
            return infoDate > yesterday;
        }

        $scope.getOrgaDiscount = function (entry, service) {
            var discount = 0;
            if (entry.gsma_contract) {
                var initial_price = $scope.getValue(entry, service) / (1 - entry.gsma_reduction / 100);
                discount = initial_price * (entry.gsma_reduction / 100);
            }
            return discount;
        }

        $scope.getOrgaDiscountSum = function (entry) {
            var total = 0;
            _.each($rootScope.FINANCIAL_TYPES, function (name, service) {
                total += $scope.getOrgaDiscount(entry, service);
            });
            return total;
        };

        $scope.getComm = function (entry) {
            return entry.gsma_contract ? 0 : entry.comm;
        }

        $scope.hasContract = function (e) {
            return e.sent
        }

        $scope.hasResult = function () {
            if ($scope.entries)
                return $scope.entries.length > 0
            return false;
        }

        $scope.getTotal = function (field, results) {
            var total = 0;

            if (!results) {
                return;
            }
            results.forEach(function (entry) {
                if (field === 'total') {
                    total += $scope.getSum(entry);
                } else if (field === 'orga_comm') {
                    total += $scope.getSum(entry) * $scope.getComm(entry) / 100;
                } else if (field === 'orga_disc') {
                    total += $scope.getOrgaDiscountSum(entry);
                } else {
                    total += $scope.getValue(entry, field);
                }
            });
            return total;
        };

        var extractGsmaStatus = function (file) {
            var stat = file.submit_to_gsma ? file.gsma_status : 'Not necessary validation'
            return (stat === 'Rejected') ? 'On hold' :
                ((stat === 'Accepted') ? 'Confirmed' : stat);
        }
        var compute = function (file) {
            if (!$scope.entries) {
                $scope.entries = [];
            }
            var entry = {
                _id: file._id,
                name: file.name,
                company: file.company.name,
                pm: file.pm,
                people: file.people,
                type: file.type,
                date: file.date,
                created: file.created,
                hotel_name: file.hotel_name,
                final_client: file.final_client,
                reference: file.references,
                sent: file.date_sent,
                due: file.sold_out_date,
                all: {},
                confirmed: {},
                pending: {},
                comm: file.comm,
                project_id: file.project_id,
                comment_organisator: file.comment_organisator,
                discount: 0,
                new_client: file.company.new_client ? true : false,
                origin: file.new_client_source || '-',
                gsma_status: extractGsmaStatus(file),
                gsma_contract: file.gsma_contract,
                gsma_reduction: file.gsma_reduction,
                submit_to_gsma: file.submit_to_gsma,
                status: file.status,
                test: {},
                test2: {},
                hotel_venues_offered: file.hotel_venues_offered
            };
            _.each($rootScope.FINANCIAL_TYPES, function (name, service) {
                var val = file[service];
                entry['all'][service] = val || 0;
                entry['confirmed'][service] = (file.confirmed_services && file.confirmed_services[service] ? val : 0) || 0;
                entry['pending'][service] = (file.confirmed_services && file.confirmed_services[service] ? 0 : val) || 0;
            });

            console.log("TEST", file.hotel_venues_offered);

            // entry.group = ( entry.status === 'cancelled') 
            //                 ? entry.status 
            //                 : (entry['confirmed']['meeting_space'] > 0) ? 'confirmed' : 'pending'
            entry.group = (entry.status === 'cancelled')
                ? entry.status
                : (file.confirmed_services['meeting_space']) ? 'confirmed' : 'pending'

            //            if(entry.final_client === "1NCE")
            {
                // NOTE : ne pas afficher dans le turn over les files non validés
                if (entry.submit_to_gsma && entry.gsma_status == "Pending") {
                    console.log("TO BE ACCEPTED  file " + " " + entry.name + " " + entry.submit_to_gsma);
                    entry.group = "test";
                    entry.status = "test";
                    $scope.entries.push(entry);
                }
                else if (entry.submit_to_gsma && entry.gsma_status == "On hold") {
                    console.log("ONHOLD  file " + " " + entry.name + " " + entry.submit_to_gsma);
                    entry.status = "test2";
                    entry.group = "test2";
                    $scope.entries.push(entry);
                }
                // if ((entry.gsma_status == 'On hold') || (entry.gsma_status == 'Pending')) {
                //   console.log("Ignore  file " + " " + entry.name + " " + entry.submit_to_gsma);
                // }
                else {
                    // console.log(file.company.name + " >> " + entry.group);
                    $scope.entries.push(entry);
                }
            }
        };

        load();

        $scope.openComment = function (file) {
            $scope.editedFile = file;
            ngDialog.openConfirm({
                template: 'comment-edition',
                scope: $scope,
                width: '800px'
            }).then(function () {
                file.comment_organisator = $scope.editedFile.comment_organisator;
                $rootScope.loading(true);
                File.changeComment(file, file.comment_organisator).then(function () {
                    $rootScope.loading(false);
                });
            }, function () { });
        };

        $scope.calculateColspan = function () {
            var cols = 0;
            _.each($scope.has, function (value) {
                if (value) {
                    cols++;
                }
            });
            return cols;
        };

        $scope.hasServices = function () {
            return !!($scope.calculateColspan());
        };

        $scope.projectFilter = function (entry) {
            return (!$scope.filters.project || entry.project_id === $scope.filters.project._id);
        };

        var slugify = function (text) {
            return text.toString().toLowerCase()
                .replace(/\s+/g, '-')           // Replace spaces with -
                .replace(/[^\w\-_]+/g, '')      // Remove all non-word chars
                .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                .replace(/^-+/, '')             // Trim - from start of text
                .replace(/-+$/, '');            // Trim - from end of text
        };


        var createGroupTable = function (group, entries) {
            var rows = [];
            var header = [];

            rows.push([]); // empty line
            rows.push([]); // empty line
            rows.push([{ bold: true, value: group.title, color: group.t_color }]);
            rows.push([]); // empty line
            header.push({ bold: true, value: 'Client company name', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'New/Old client', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'N° guests', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'Event Type', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'Event date', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'Hotel / Venue', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'File Status', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'GSMA Status', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'Request reception', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'Origin of the request', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'Contract sent', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'Deadline', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'Service type', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'Hotel price', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'Other services price', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'Total', background: group.color, color: '#222222' });
            header.push({ bold: true, value: '% Comm', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'GSMA Commission', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'Discount applied over meetings', background: group.color, color: '#222222' });
            header.push({ bold: true, value: 'Comment', background: group.color, color: '#222222' });
            rows.push(header);

            entries = _.orderBy(entries, 'company');

            rows = rows.concat(entries.map(function (entry) {
                var columns = [];
                columns.push({ value: ((entry.final_client) ? entry.final_client : entry.company), bold: true, background: $scope.isNew(entry.created) ? '#8089dc' : '' });
                columns.push({ value: entry.new_client ? 'New' : '' });
                columns.push({ value: entry.people });
                columns.push({ value: entry.type });
                columns.push({ value: entry.date });
                columns.push({ value: entry.hotel_name });
                columns.push({ value: entry.group, color: group.t_color, bold: true });
                columns.push({ value: entry.gsma_status });
                columns.push({ value: $filter('date')(entry.created, 'dd/MM/yyyy'), color: (entry.group === 'pending') ? group.t_color : '#000' });
                columns.push({ value: entry.origin });
                columns.push({ value: $filter('date')(entry.sent, 'dd/MM/yyyy'), color: (entry.group === 'pending') ? group.t_color : '#000', background: $scope.isNew(entry.sent) ? '#8089dc' : '' });
                columns.push({ value: $filter('date')(entry.due, 'dd/MM/yyyy'), color: (entry.group === 'pending') ? group.t_color : '#000' });
                columns.push({ value: 'Room Rental' });
                columns.push({ value: $scope.getValue(entry, 'meeting_space'), type: 'money' });
                columns.push({ value: 0, type: 'money' });
                columns.push({ value: $scope.getValue(entry, 'meeting_space'), type: 'money' });
                columns.push({ value: ($scope.getComm(entry) * 0.01), type: 'percent' });
                columns.push({ value: ($scope.getValue(entry, 'meeting_space') * $scope.getComm(entry) / 100), type: 'money' });
                columns.push({ value: $scope.getOrgaDiscount(entry, 'meeting_space'), type: 'money' });
                columns.push({ value: entry.comment_organisator });
                return columns;
            }));

            return rows;
        };


        $scope.exportToXls = function () {
            var data = [];
            $scope.groups.forEach(function (group) {
                if ($scope.filters.status === 'all' || $scope.filters.status === group.status) {
                    var entr = $scope.getEntries(group);
                    entr = _.filter(entr, $scope.projectFilter);
                    entr = $filter('filter')(entr, $scope.filters.text);
                    data = data.concat(createGroupTable(group, entr));
                }
            });
            $scope.exporting = true;
            console.log(data)
            Xls.downloadXls("numbers.xlsx", data).then(function () {
                $scope.exporting = false;
            });
            $scope.data = data;
        };
    }
]);
