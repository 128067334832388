/* global app */

'use strict';

app.service('Company', [
    '$http',
    function ($http) {
        var Company = {
            fetch: function (id) {
                var endpoint = id ? 'companies/' + id + '/' : 'companies/';

                return $http.get(app.settings.api.url + endpoint).then(function (response) {
                    //console.log('Company fetch ok', response);

                    return response && response.data;
                }).catch(function (error) {
                    //console.log('Company fetch error', error);

                    return { error: error };
                });
            },

            delete: function (id) {
                return $http.delete(app.settings.api.url + 'companies/' + id + '/').then(function (response) {
                    //console.log('Company delete ok', response);

                    return response && response.data;
                }).catch(function (error) {
                    //console.log('Company delete error', error);

                    return { error: error };
                });
            },

            restore: function (id) {
                return $http.put(app.settings.api.url + 'companies/' + id + '/', {
                    enabled: true
                }).then(function (response) {
                    //console.log('Company restore ok', response);

                    return response && response.data;
                }).catch(function (error) {
                    //console.log('Company restore error', error);

                    return { error: error };
                });
            },

            save: function (data) {
                var method   = data._id ? 'put' : 'post',
                    endpoint = data._id ? 'companies/' + data._id + '/' : 'companies/';

                //console.log('Company ' + method, data);

                return $http[method](app.settings.api.url + endpoint, data).then(function (response) {
                    //console.log('Company save ok', response);

                    return response && response.data;
                }).catch(function (error) {
                    //console.log('Company save error', error);

                    return { error: error };
                });
            },
        };

        return Company;
    }
]);
