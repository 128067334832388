/* global app, _ */

'use strict';

app.controller('ClientFilesDetailsCtrl', [
    '$rootScope',
    '$scope',
    '$stateParams',
    'File',
    'Company',
    'User',
    'ngDialog',
    function ($rootScope, $scope, $stateParams, File, Company,User, ngDialog) {

        // Get company's info

        File.fetch($stateParams.id, { populate: true }).then(function (response) {
            $scope.file = response;
            $scope.pm = response.project_manager;
            $scope.company = response.billing_company;

            _.each($scope.file.bookings, function(booking) {
                var service_id = booking.service;

                _.find($scope.file.project.suppliers, function(supplier) {
                    if (supplier.supplier === booking.supplier) {
                        _.find(supplier[booking.type], function(service) {
                            if (service._id === service_id) {
                                booking.service = service;
                                booking.supplier = supplier;
                                return true;
                            }
                        });
                        return true;
                    }
                });
            });
        });

        File.fetchPlanning($stateParams.id, null, "day").then(function(rooms) {
            $scope.rooms = rooms;

            if (rooms.length) {
                $scope.days = _.map(rooms[0].dates, function(date) { return {date: new Date(date.date)}; } );
            }

            $scope.venues = _.chain(rooms)
                            .filter(function (room) {
                                if ($stateParams.venue) {
                                    return room.supplier._id === $stateParams.venue;
                                }
                                return true;
                            })
                            .groupBy("supplier._id")
                            .values()
                            .value();
        });

        $scope.computeRequestMailTo = function () {
            if (!$scope.file) {
                return;
            }

            var a = '',
                body = [],
                company = $scope.company,
                pm = $scope.pm,
                file = $scope.file,
                subject = 'Request // ' + file.name + ' — #' + file.file_number;

            body = [
                'File:',
                file.name + ' — #' + file.file_number,
                '',
                'Company:',
                company.name,
                '',
            ].join('\n');

            a += 'mailto:' + pm.email;
            a += '?subject=' + subject;
            a += '&body=' + encodeURIComponent(body);

            return a;
        };

        $scope.computeReportMailTo = function () {
            if (!$scope.file) {
                return;
            }

            var a = '',
                body = [],
                file = $scope.file,
                company = $scope.company,
                pm = $scope.pm,
                subject = 'Report data error // ' + file.name + ' — #' + file.file_number;

            body = [
                'Company:',
                company.name,
                '',
                'Address:',
                (company.address || '-') + ' — ' + (company.zipcode || '-') + ' ' + (company.city || '-'),
                '',
                'Country:',
                company.country,
                '',
                'VAT number:',
                company.vat_number,
                '',
                'CIF number:',
                company.cif_number,
                ''
            ].join('\n');

            a += 'mailto:' + pm.email;
            a += '?subject=' + subject;
            a += '&body=' + encodeURIComponent(body);

            return a;
        };

        $scope.has_service = function (meeting_space_id, service_type, unit) {
            if (typeof unit === "string" || !unit) {
                return false;
            }

            return _.find($scope.file.bookings, function(booking) {
                return booking.type === service_type &&
                    unit.date.from <= booking.date.from &&
                    booking.date.to <= unit.date.to &&
                    booking.meeting_space === meeting_space_id;
            });
        };

        var get_services = function(meeting_space_id, unit) {
            return _.filter($scope.file.bookings, function(booking) {
                return unit.date.from <= booking.date.from &&
                    booking.date.to <= unit.date.to &&
                    booking.meeting_space === meeting_space_id;
            });
        };

        $scope.openServiceSummary = function (meeting_space, unit) {
            var meeting_space_id = meeting_space._id;

            $scope.selected = {};
            $scope.selected.venue = meeting_space.supplier.name;
            $scope.selected.room = meeting_space.name;
            $scope.selected.date = unit.date.from;
            $scope.selected.services = get_services(meeting_space_id, unit);

            ngDialog.open({
                template: 'dialog-services-summary',
                scope: $scope,
                width: '1200px'
            });
        };
    }
]);

