/* global app */

'use strict';

app.service('Project', [
    '$http',
    function ($http) {
        var Project = {

          turnover: function (id, params) {
                var endpoint = 'projects/' + id + '/turnover/';
                var query_string = params ? _.map(params, function(v, k) {
                    return k + "=" + encodeURIComponent(JSON.stringify(v));
                }).join("&"): '';

                return $http.get(app.settings.api.url + endpoint ).then(function (response) {
                    //console.log('Project turnover fetch ok', response);

                    var data = response.data;
                    if(data.dates){
                        data.dates.from = new Date(data.dates.from);
                    }
                    return response && data;
                }).catch(function (error) {
                    //console.log('Project turnover fetch error', error);
                    return { error: error };
                });
            },

          toValid: function (id, params) {
                var endpoint = 'projects/' + id + '/files_to_valid/';
                var query_string = params ? _.map(params, function(v, k) {
                    return k + "=" + encodeURIComponent(JSON.stringify(v));
                }).join("&"): '';

                return $http.get(app.settings.api.url + endpoint ).then(function (response) {
                    //console.log('Project turnover fetch ok', response);

                    var data = response.data;
                    if(data.dates){
                        data.dates.from = new Date(data.dates.from);
                    }
                    return response && data;
                }).catch(function (error) {
                    //console.log('Project turnover fetch error', error);
                    return { error: error };
                });
            },

        };

        return Project;
    }
]);
