/* global app */

'use strict';

var nativeFile = File;

app.service('File', [
    '$http',
    function ($http) {
        var File = {
            fetch: function (id, params) {
                //console.log("File fetch...");
                var endpoint = id ? 'files/' + id + '/' : 'files/';
                var query_string = params ? _.map(params, function(v, k) {
                    return k + "=" + encodeURIComponent(JSON.stringify(v));
                }).join("&"): '';

                return $http.get(app.settings.api.url + endpoint + "?" + query_string).then(function (response) {
                    //console.log('File fetch ok', response);

                    return response && response.data;
                }).catch(function (error) {
                    //console.log('File fetch error', error);

                    return { error: error };
                });
            },

            fetchPlanning: function(id, supplier_id, force_unit) {
                var endpoint = 'files/' + id + '/planning/';
                if (supplier_id) { endpoint += supplier_id; }

                if (force_unit) { endpoint += "?force_unit=" + force_unit; }

                return $http.get(app.settings.api.url + endpoint).then(function (response) {
                    //console.log('Planning fetch ok', response);

                    return response && response.data;
                }).catch(function (error) {
                    //console.log('Planning fetch error', error);

                    return { error: error };
                });
            },

            changeStatus: function (file, status) {
                return $http({
                    method: 'put',
                    url: app.settings.api.url + 'files/' + file._id + '/' ,
                    headers:  {
                        'Content-Type': 'application/json'
                    },
                    data: {  'gsma_status' : status }
                }).then(function (response) {
                    //console.log('File save ok', response);
                    return response && response.data;
                }).catch(function (error) {
                    //console.log('File save error', error);
                    return { error: error };
                });
            },

            changeComment: function (file, comment) {
                return $http({
                    method: 'put',
                    url: app.settings.api.url + 'files/' + file._id + '/' ,
                    headers:  {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'comment_organisator': comment
                    }
                }).then(function (response) {
                    //console.log('File save ok', response);
                    return response && response.data;
                }).catch(function (error) {
                    //console.log('File save error', error);
                    return { error: error };
                });
            },
        };

        return File;
    },

]);
